<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12" md="12">
          <v-card class="d-flex align-center rounded-lg pa-2" outlined>
            <h4 class="">{{ groupInfo ? groupInfo.groupName : "" }}</h4>
            <v-spacer></v-spacer>
            <v-btn tile color="primary" @click="back()" class="rounded-xl">
              {{ $t("back") }}
              <v-icon right> mdi-arrow-left </v-icon>
            </v-btn>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" :md="showMemberList ? '8' : '12'" sm="12">
          <v-card outlined elevation="0" class="cl" id="chat">
            <v-toolbar dense color="primary" dark>
              <v-menu left bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    v-if="this.isInRole(2)"
                  >
                    الخيارات
                    <v-icon class="px-1">mdi-cog-outline</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <!-- <v-list-item @click="() => {}">
                    <v-list-item-title>دعوة</v-list-item-title>
                  </v-list-item>
                  <v-divider></v-divider> -->
                  <!-- <v-list-item @click="() => {}">
                    <v-list-item-title>ارشفة</v-list-item-title>
                  </v-list-item> -->
                  <v-list-item
                    @click="changeGroupState(0, myGroupInfo.groupStatus)"
                    v-if="myGroupInfo.groupMode == 1"
                  >
                    <v-list-item-title>يمكن للجميع الإرسال</v-list-item-title>
                  </v-list-item>
                  <v-list-item
                    @click="changeGroupState(1, myGroupInfo.groupStatus)"
                    v-if="myGroupInfo.groupMode == 0"
                  >
                    <v-list-item-title
                      >يمكن للإستاذ فقط الإرسال</v-list-item-title
                    >
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-list-item @click="changeMemberStatus(6)">
                    <v-list-item-title>كتم الكل </v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="changeMemberStatus(7)">
                    <v-list-item-title>الغاء كتم الكل</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-spacer></v-spacer>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    v-on="on"
                    icon
                    dark
                    @click="showMemberList = !showMemberList"
                  >
                    <v-icon v-if="!showMemberList"
                      >mdi-account-multiple-check-outline</v-icon
                    >
                    <v-icon v-else>mdi-account-multiple-remove-outline</v-icon>
                  </v-btn>
                </template>
                <span v-if="!showMemberList">اضهار اعضاء المجموعة</span>
                <span v-else>اخفاء اعضاء المجموعة</span>
              </v-tooltip>
            </v-toolbar>

            <div
              id="container"
              style="
                max-height: calc(65vh);
                min-height: 300px;
                height: calc(65vh);
                overflow-y: auto;
              "
            >
              <ul class="mt-4">
                <infinite-loading
                  v-if="messagesList.length"
                  spinner="spiral"
                  @infinite="infiniteScroll"
                  direction="top"
                ></infinite-loading>
                <li v-for="(item, index) in messagesList" :key="index">
                  <v-col
                    cols="12"
                    class="text-center"
                    v-if="
                      index == 0 ||
                      messagesList[index].datex.substr(0, 10) !=
                        messagesList[index - 1].datex.substr(0, 10)
                    "
                  >
                    <v-chip disabled outlined>
                      {{ item.datex | moment("YYYY/MM/DD") }}
                    </v-chip>
                  </v-col>
                  <div
                    class="py-2 px-3"
                    v-if="
                      index == 0 ||
                      messagesList[index].memberName !=
                        messagesList[index - 1].memberName
                    "
                  >
                    <span
                      style="font-size: 12px"
                      :style="`color:${stringToHslColor(
                        item.memberName,
                        40,
                        50
                      )}`"
                      :class="
                        item.memberGuid == getUserInfo().userId
                          ? 'text-right d-flex justify-start'
                          : 'text-left  d-flex justify-end'
                      "
                      >{{ item.memberName }}</span
                    >
                  </div>
                  <v-col
                    :class="
                      item.memberGuid == getUserInfo().userId
                        ? 'text-right my-0 py-0 d-flex justify-start'
                        : 'text-left my-0 py-0 d-flex justify-end'
                    "
                  >
                    <v-hover v-slot="{ hover }">
                      <v-card
                        class="mb-2 pt-1 pb-0 px-2"
                        :class="
                          item.memberGuid == getUserInfo().userId
                            ? 'rounded-l-lg rounded-tr-0 rounded-br-lg ml-10'
                            : 'rounded-r-lg rounded-tl-0 rounded-bl-lg mr-10'
                        "
                        :color="
                          item.memberGuid == getUserInfo().userId
                            ? 'grey lighten-2'
                            : ''
                        "
                        outlined
                        width="fit-content"
                      >
                        <v-row
                          class="pa-0 ma-0 pt-2"
                          v-if="item.attachedFile && !item.deleteFromAll"
                        >
                          <v-col cols="12" md="12" class="pa-0 ma-0">
                            <v-img
                              v-if="
                                get_url_extension(item.attachedFile) == 'jpg' ||
                                get_url_extension(item.attachedFile) ==
                                  'jpeg' ||
                                get_url_extension(item.attachedFile) == 'png' ||
                                get_url_extension(item.attachedFile) == 'gif' ||
                                get_url_extension(item.attachedFile) == 'bmp'
                              "
                              lazy-src="../../assets/loadImg.svg"
                              :src="`${pathUrl}Content/ChatGroupFilesPath/${item.groupGuid}/${item.attachedFile}`"
                              width="100%"
                              max-width="250"
                            ></v-img>
                            <img
                              src="../../assets/fileType/pdf.svg"
                              width="100px"
                              v-else-if="
                                get_url_extension(item.attachedFile) == 'pdf'
                              "
                            />
                            <img
                              src="../../assets/fileType/doc.svg"
                              width="100px"
                              v-else-if="
                                get_url_extension(item.attachedFile) == 'doc' ||
                                get_url_extension(item.attachedFile) == 'docx'
                              "
                            />
                            <img
                              src="../../assets/fileType/ppt.svg"
                              width="100px"
                              v-else-if="
                                get_url_extension(item.attachedFile) ==
                                  'pptx' ||
                                get_url_extension(item.attachedFile) == 'ppt'
                              "
                            />
                            <img
                              src="../../assets/fileType/xls.svg"
                              width="100px"
                              v-else-if="
                                get_url_extension(item.attachedFile) == 'xls' ||
                                get_url_extension(item.attachedFile) == 'xlsx'
                              "
                            />
                            <img
                              src="../../assets/fileType/mp4.svg"
                              width="100px"
                              v-else-if="
                                get_url_extension(item.attachedFile) == 'mp4' ||
                                get_url_extension(item.attachedFile) == 'wmv' ||
                                get_url_extension(item.attachedFile) == 'wav' ||
                                get_url_extension(item.attachedFile) ==
                                  'weba' ||
                                get_url_extension(item.attachedFile) ==
                                  'webm' ||
                                get_url_extension(item.attachedFile) == 'avi' ||
                                get_url_extension(item.attachedFile) == 'flv'
                              "
                            />
                            <img
                              src="../../assets/fileType/mp3.svg"
                              width="100px"
                              v-else-if="
                                get_url_extension(item.attachedFile) == 'mp3' ||
                                get_url_extension(item.attachedFile) == 'aac'
                              "
                            />
                            <img
                              src="../../assets/fileType/txt.svg"
                              width="100px"
                              v-else-if="
                                get_url_extension(item.attachedFile) == 'txt'
                              "
                            />
                            <img
                              src="../../assets/fileType/file.svg"
                              width="100px"
                              v-else
                            />
                            <p
                              v-if="
                                get_url_extension(item.attachedFile) != 'jpg' &&
                                get_url_extension(item.attachedFile) !=
                                  'jpeg' &&
                                get_url_extension(item.attachedFile) != 'png' &&
                                get_url_extension(item.attachedFile) != 'gif' &&
                                get_url_extension(item.attachedFile) != 'bmp'
                              "
                              style="
                                font-size: 10px;
                                color: #8c8c8c;
                                width: 100px;
                              "
                            >
                              {{ item.attachedFile }}
                            </p>
                          </v-col>
                          <v-fade-transition>
                            <v-overlay v-if="hover" absolute color="#111">
                              <v-btn icon @click="insLec(item)">
                                <v-icon
                                  color="white"
                                  size="48"
                                >mdi-progress-download</v-icon
                              ></v-btn>
                            </v-overlay>
                          </v-fade-transition>
                        </v-row>
                        <v-row class="pa-0 ma-0">
                          <v-col cols="12" md="12" class="pa-0 ma-0">
                            <span v-if="item.deleteFromAll" class="grey--text">
                              {{ `هذه الرسالة محذوفة` }}
                              <v-icon color="grey">mdi-cancel</v-icon>
                            </span>
                            <span
                              v-else
                              v-html="item.textMessage ? item.textMessage : ''"
                            >
                            </span>
                          </v-col>
                          <v-col
                            cols="12"
                            md="1"
                            class="pa-0 ma-0"
                            v-if="item.memberGuid == getUserInfo().userId"
                          >
                            <v-chip
                              small
                              color="error deletButton"
                              v-if="hover && !item.deleteFromAll"
                              :class="{ 'on-hover': hover }"
                              @click="deleteFun(item)"
                              class="mx-0 px-0 px-1"
                            >
                              <v-icon class="" small color="white"
                                >mdi-delete-outline</v-icon
                              >
                            </v-chip>
                          </v-col>
                        </v-row>
                        <p
                          style="font-size: 11px"
                          class="pt-2 pb-0 mb-1 grey--text"
                          :class="
                            item.memberGuid == getUserInfo().userId
                              ? 'text-left'
                              : 'text-right'
                          "
                        >
                          {{ item.datex | moment(" h:mm a ") }}
                        </p>
                      </v-card>
                    </v-hover>
                  </v-col>
                </li>
              </ul>
            </div>
            <v-card-actions
              v-if="
                (myGroupInfo.groupMode == 0 && !myGroupInfo.isMuted) ||
                this.isInRole(2)
              "
            >
              <emoji
                class="pl-2"
                :emoji-opened="emojiOpened"
                :position-top="true"
                @add-emoji="addEmoji"
                @open-emoji="emojiOpened = $event"
              >
                <template v-for="(i, name) in $scopedSlots" #[name]="data">
                  <slot :name="name" v-bind="data" />
                </template>
              </emoji>
              <v-text-field
                v-on:keyup.enter="message ? sendMessage(true) : ''"
                hide-details
                v-model="message"
                :append-outer-icon="message ? 'mdi-send' : 'mdi-paperclip'"
                filled
                clear-icon="mdi-close-circle"
                clearable
                label="الرسالة"
                type="text"
                @click:append-outer="sendMessage"
                @click:clear="clearMessage"
              >
                <template slot="append-outer">
                  <v-icon
                    :color="message ? 'primary' : ''"
                    @click="sendMessage(message ? true : false)"
                    >{{ message ? "mdi-send" : "mdi-paperclip" }}</v-icon
                  >
                </template>
              </v-text-field>
            </v-card-actions>
            <v-card-actions v-else>
              <v-col class="text-center">لا يمكن ارسال رسائل للمجموعة</v-col>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col cols="12" md="4" sm="12" v-if="showMemberList">
          <GroupMempers />
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="deleteDialog" max-width="300">
      <v-card>
        <v-card-title class="text-h5">
          هل انت متاكد من حذف الرسالة ؟
        </v-card-title>
        <v-card-text>
          <v-checkbox v-model="deleteFromBothSides">
            <template v-slot:label>
              <div class="text-right">
                {{ `احذف من الجميع ايضا` }}
              </div>
            </template>
          </v-checkbox>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary darken-1" text @click="deleteDialog = false">
            الغاء
          </v-btn>
          <v-btn color="error darken-1" text @click="deleteMessage()">
            حذف
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <FileFiled ref="fileFiled" v-on:send-result-values="fileSended" />
  </div>
</template>

<script>
import InfiniteLoading from "vue-infinite-loading";
import { mapState } from "vuex";
import * as userHub from "../../hubs/userHub";
import GroupMempers from "../../components/chat/groupMempers.vue";

export default {
  components: {
    Emoji: () => import("../../components/emoji.vue"),
    FileFiled: () => import("../../components/fileFiled.vue"),
    GroupMempers,
    InfiniteLoading,
  },
  props: ["id"],
  data() {
    return {
      showMemberList: false,
      deleteFromBothSides: false,
      NewMessageJson: null,
      emojiOpened: false,
      show: false,
      message: "",
      marker: true,
      iconIndex: 0,
      info: null,
      messagesList: [],
      skipMessage: 0,
      deleteDialog: false,
      deleteInfo: null,
      repet: null,
      userMemberList: [],
      myGroupInfo: {},
    };
  },
  destroyed() {},
  beforeRouteLeave(to, from, next) {
    userHub.disconnectChatGroup();
    next();
  },
  beforeDestroy() {
    clearInterval(this.repet);
  },
  mounted() {
    userHub.connectionGroupChat.on("updateUser", (groupGuid) => {
      this.getListUsers();
    });
    this.repet = setInterval(() => {
      userHub.UpdateUsersCache(this.groupInfo.guid);
    }, 5000);
    userHub.connectionGroupChat.on(
      "MessageDeleted",
      (MessageGuid, deleteFromMe) => {
        if (deleteFromMe) {
          this.messagesList.forEach((item, index, object) => {
            if (MessageGuid == item.guid) {
              object.splice(index, 1);
            }
          });
        } else {
          this.messagesList.forEach((item) => {
            if (MessageGuid == item.guid) {
              item.deleteFromAll = true;
            }
          });
        }
      }
    );
    userHub.connectionGroupChat.on(
      "ReceiveMessage",
      (
        GroupGuid,
        UserGuid,
        UserName,
        MessageGuid,
        MessageText,
        TimeString,
        MemberType,
        MemberCode,
        FileName,
        FileType,
        FormatSize
      ) => {
        const data = {
          GroupGuid: GroupGuid,
          UserGuid: UserGuid,
          UserName: UserName,
          MessageGuid: MessageGuid,
          MessageText: MessageText,
          TimeString: TimeString,
          MemberType: MemberType,
          MemberCode: MemberCode,
          FileName: FileName,
          FileType: FileType,
          FormatSize: FormatSize,
        };
        // var o = document.getElementById("chat");
        if (
          data.GroupGuid == this.groupInfo.guid &&
          data.UserGuid == this.getUserInfo().userId
        ) {
          const item = {
            attachedFile: data.FileName,
            attachedFileFormatSize: data.FormatSize,
            attachedFileSize: 0,
            attachedFileType: data.FileType,
            datex: new Date(`${new Date().toString().split("GMT")[0]} UTC`)
              .toISOString()
              .split(".")[0],
            deleteFromAll: false,
            deleteFromAllDate: "0001-01-01T00:00:00",
            deleteFromMe: false,
            memberGuid: data.UserGuid,
            memberType: data.MemberType,
            memberCode: data.MemberCode,
            memberName: data.UserName,
            guid: data.MessageGuid,
            isMine: 1,
            lastMsgDate: null,
            textMessage: data.MessageText,
            groupGuid: data.GroupGuid,
            userGuid: null,
          };
          this.messagesList.push(item);
          this.scrollToEnd();
        } else if (
          data.GroupGuid == this.groupInfo.guid &&
          data.UserGuid != this.getUserInfo().userId
        ) {
          const item = {
            attachedFile: data.FileName,
            attachedFileFormatSize: data.FormatSize,
            attachedFileSize: 0,
            attachedFileType: data.FileType,
            datex: new Date(`${new Date().toString().split("GMT")[0]} UTC`)
              .toISOString()
              .split(".")[0],
            deleteFromAll: false,
            deleteFromAllDate: "0001-01-01T00:00:00",
            deleteFromMe: false,
            memberGuid: data.UserGuid,
            memberType: data.MemberType,
            memberCode: data.MemberCode,
            memberName: data.UserName,
            guid: data.MessageGuid,
            isMine: 1,
            lastMsgDate: null,
            textMessage: data.MessageText,
            groupGuid: data.GroupGuid,
            userGuid: null,
          };
          this.messagesList.push(item);
          this.scrollToEnd();
          userHub.ReadMessage(data.MessageGuid, this.groupInfo.guid);
        }
      }
    );
  },
  computed: {
    getColorFromName(c) {
      return this.stringToHslColor(c.userName, 40, 50);
    },
    ...mapState(["pathUrl"]),
    groupInfo: {
      get() {
        return this.$store.state.groupInfo;
      },
      set(val) {
        this.$store.commit("SET_GROUPINFO", val);
      },
    },
  },
  async beforeCreate() {
    await userHub.createConnectionChatGroup();
    await userHub.startChatGroup();
  },
  created() {
    this.Clear();
    console.log(this.groupInfo);
    setTimeout(() => {
      userHub.Join(this.id);
      this.getMessagesUser(0);
      this.scrollToEnd();
      this.getListUsers();
    }, 500);
  },
  methods: {
    changeMemberStatus(status) {
      userHub.ChangeMemberStatus(
        this.groupInfo.guid,
        this.getUserInfo().userId,
        status
      );
    },
    changeGroupState(mode, status) {
      userHub.connectionGroupChat
        .invoke("UpdateUserGroup", this.groupInfo.guid, mode, status)
        .catch((err) => {
          console.log(err);
        });
    },
    getListUsers() {
      setTimeout(() => {
        userHub.connectionGroupChat
          .invoke("GetUsers", this.groupInfo.guid)
          .then((res) => {
            this.userMemberList = res;
            this.userMemberList.forEach((e) => {
              if (e.guid == getUserInfo().userId) {
                this.myGroupInfo = e;
                console.log(e);
              }
            });
          });
      }, 200);
    },
    back() {
      this.$router.push("chats");
    },
    stringToHslColor(str, s, l) {
      let hash = 0;
      for (let i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
      }

      const h = hash % 360;
      return `hsl(${h}, ${s}%, ${l}%)`;
    },
    insLec(item) {
      this.axios({
        url: `${this.pathUrl}Content/ChatGroupFilesPath/${item.groupGuid}/${item.attachedFile}`,
        method: "GET",
        responseType: "blob",
      })
        .then((response) => {
          const blob = new Blob([response.data], {
            type: response.data.type,
          });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = item.attachedFile;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(() => {
          window.open(
            `${this.pathUrl}Content/ChatGroupFilesPath/${item.fromGuid}/${item.toGuid}/${item.attachedFile}`,
            "_blank"
          );
          // this.$swal.fire({
          //   title: this.$t("TheFileDoesNotExist"),
          //   text: "",
          //   icon: "error",
          //   confirmButtonText: this.$t("close"),
          // });
        });
    },
    fileSended() {},
    closeChat() {
      this.show = false;
    },

    deleteMessage() {
      userHub.DeleteMessage(
        this.deleteInfo.guid,
        this.groupInfo.guid,
        !this.deleteFromBothSides
      );
      this.deleteDialog = false;
    },
    deleteFun(item) {
      this.deleteInfo = item;
      this.deleteDialog = true;
    },
    linkify(inputText) {
      let replacedText; let replacePattern1; let replacePattern2; let
replacePattern3;

      // URLs starting with http://, https://, or ftp://
      replacePattern1 =
        /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
      replacedText = inputText.replace(
        replacePattern1,
        '<a href="$1" target="_blank">$1</a>'
      );

      // URLs starting with "www." (without // before it, or it'd re-link the ones done above).
      replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
      replacedText = replacedText.replace(
        replacePattern2,
        '$1<a href="http://$2" target="_blank">$2</a>'
      );

      // Change email addresses to mailto:: links.
      replacePattern3 =
        /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;
      replacedText = replacedText.replace(
        replacePattern3,
        '<a href="mailto:$1">$1</a>'
      );

      const youtubePattern =
        /(?:http?s?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/(?:watch\?v=)?(\S+)/g;

      if (youtubePattern.test(inputText)) {
        const replacement =
          '<iframe style="width:500px;max-width:100%;" height="345" src="https://www.youtube.com/embed/$1" frameborder="0" allowfullscreen></iframe>';
        replacedText = inputText.replace(youtubePattern, replacement);
        // For start time, turn get param & into ?
        replacedText = replacedText.replace("&amp;t=", "?t=");
      }
      console.log(replacedText);
      return replacedText;
    },
    Clear() {
      this.messagesList = [];
      this.skipMessage = 0;
    },
    async infiniteScroll($state) {
      this.skipMessage = this.messagesList.length;
      const messagesGet = await userHub.LoadMessages(
        this.groupInfo.guid,
        this.skipMessage
      );
      //   console.log(messagesGet);
      this.messagesList.reverse();
      if (messagesGet.length > 1) {
        messagesGet.forEach((item) => this.messagesList.push(item));
        $state.loaded();
      } else {
        $state.complete();
      }
      this.messagesList.reverse();
    },

    addEmoji(emoji) {
      this.message += `${emoji.icon}`;
      // console.log(emoji.icon);
    },
    toggleMarker() {
      this.marker = !this.marker;
    },
    sendMessage(value) {
      if (value) {
        userHub.SendMessage(this.groupInfo.guid, this.message);
        this.resetIcon();
        this.clearMessage();
      } else {
        this.$refs.fileFiled.setValue("ChatGroup", this.groupInfo);
      }
    },
    clearMessage() {
      this.message = "";
    },
    resetIcon() {
      this.iconIndex = 0;
    },
    get_url_extension(url) {
      return url.split(/[#?]/)[0].split(".").pop().trim().toLowerCase();
    },
    scrollToEnd() {
      setTimeout(() => {
        const container = this.$el.querySelector("#container");
        container.scrollTop = container.scrollHeight;
      }, 100);
    },
    async getMessagesUser(skipMessage) {
      this.messagesList = await userHub.LoadMessages(
        this.groupInfo.guid,
        skipMessage
      );
      this.messagesList.reverse();
    },
  },

};
</script>
<style lang="scss" scoped>
.v-card > .v-toolbar {
  position: sticky;
  top: 0;
  //   z-index: 99;
}
.cl {
  //   overflow: scroll !important;
  overflow-x: hidden !important;
}
::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background: #109afd;
}
::-webkit-scrollbar {
  width: 1px;
}

.cssExample {
  overflow: hidden !important;
}
.cssExample:hover {
  overflow: auto !important;
}
.v-application ul,
.v-application ol {
  padding-left: 0px;
}
.mdi-send {
  transform: rotate(180deg);
}

.deletButton {
  position: absolute;
  top: 0;
  left: -15px;
  z-index: 5;
}
</style>